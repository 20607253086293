import React, { Component, useState, useEffect, useRef } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { createMuiTheme } from '@material-ui/core';
import SearchProps from './props';
import { useLinkAttributes } from '../../utils/linkContext';

let idFor = (val: string): string => {
	return val.replace(/[^a-z0-9]/gi, '_');
}

const SearchBoxInner = ({ id, classes, target }: SearchProps, ref: React.Ref<any>) => {
	const inputEl = useRef<HTMLInputElement>(null);
	const [defaultValue, setDefaultValue] = useState<string>(null);
	const [value, setValue] = useState<string>(null);
	const [submitState, setSubmitState] = useState<'normal' | 'reset' | 'submitting'>('normal');
        const lp = useLinkAttributes(target);
	const [linkEl, setLinkEl] = useState<HTMLAnchorElement>(null);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const params = { q: inputEl.current.value };
		const paramString = new URLSearchParams(params);
		let x = new URL(linkEl?.href ?? location.href);
		x.search = `?${paramString}`;
		if (!linkEl) {	
			// @ts-ignore
			mainBundle('./lib/client.js').update(x.toString());
			setSubmitState('submitting');
		} else {
			location.href = x.toString();
		}
	};

	const handleInput = () => {
		setValue(inputEl.current.value);
	}

	const handleReset = () => {
		inputEl.current.value = '';
		setValue('');
		setSubmitState('submitting');
		let x = new URL(linkEl?.href ?? location.href);
		x.search = ``;
		if (linkEl) location.href = x.toString()
		else {
			// @ts-ignore
			mainBundle('./lib/client.js').update(x.toString());
		}
	}

	useEffect(() => {
		let usp: URLSearchParams;
		usp = new URLSearchParams(location.search);
		setDefaultValue(usp.get('q') ?? '');
		setValue(usp.get('q') ?? '');
		if (usp.get('test')) setSubmitState('submitting');
	}, []);

	useEffect(() => {
		let usp: URLSearchParams;
		usp = new URLSearchParams(location.search);
		if (usp.get('test')) { setSubmitState('submitting'); return; }

		if (submitState == 'normal') {
			if (defaultValue == value) setSubmitState('reset');
		} else if (submitState == 'reset') {
			if (defaultValue != value) setSubmitState('normal');
		}
	}, [submitState, defaultValue, value]);

	return (
		<>
			<form className={clsx(classes.root)} onSubmit={handleSubmit}>
				{lp ? <a ref={setLinkEl} {...lp} style={{ display: 'none' }}/> : null}
				<TextField
					key={defaultValue === null ? "server" : "client"}
					className={clsx(classes.input)}
					inputRef={inputEl}
					name="q"
					id={idFor(`field ${id} q`)}
					defaultValue={defaultValue || ''}
					onChange={handleInput}
					variant='outlined'
					InputProps={{
						endAdornment: <InputAdornment position="end">
							{submitState == 'submitting' ? <span className={classes.loader}/> : (								
								(submitState == 'normal' || (submitState == 'reset' && !value)) ? (
									<IconButton className={classes.iconButton} aria-label="search" type="submit">
										<SearchIcon />
									</IconButton>
								) : (
									<IconButton className={classes.iconButton} aria-label="reset" type="reset" onClick={handleReset}>
										<ClearIcon />
									</IconButton>
								)
							)}
						</InputAdornment>
					}}
				/>
			</form>
		</>
	);
};

const StyledSearchBoxInner = ThemeLoader(
	StyleLoader(
		SearchBoxInner,
		makeStyles<ReturnType<typeof createMuiTheme>>(
			theme => ({
				root: {
					display: 'block',
					marginBottom: theme.spacing(2)
				},
				input: {
					width: '100%'
				},
				iconButton: {},
				loader: {
					padding: '12px',
					boxSizing: 'border-box',
					width: '48px',
					height: '48px',
					backgroundSize: '61px 61px',
					backgroundPosition: '50% 50%',
					backgroundRepeat: 'no-repeat',
					backgroundImage: 'url(public/loader.gif)'
				}
			}),
			{ name: 'SWSearchField' }
		)
	)
);

export default React.forwardRef(function SearchBox(props: SearchProps, ref) {
	return <StyledSearchBoxInner key={props.ts} {...props} ref={ref}/>
});


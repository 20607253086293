import React from 'react';
import { Registry, BlockPropsBase, OpaqueContent } from '@sightworks/block';

type WrapFn = (node: React.ReactElement, props: BlockPropsBase, index: number) => React.ReactElement;

export function flattenChildren(children: OpaqueContent | BlockPropsBase[]): BlockPropsBase[] {
	let bpb: BlockPropsBase[] = [];
	(children as unknown as BlockPropsBase[]).forEach(block => {
		if (block.merge) {
			if (block.mergePreserve) {
				bpb = bpb.concat(flattenChildren(Registry.getBlockType(block.type).getChildren(block)));
			} else {
				let c = flattenChildren(block.content);
				if (!c.length && '__error' in (block as any)) {
					bpb.push({
						...block,
						type: 'freeform',
						body: `<pre style="padding: 1rem; border-radius: 0.5rem; background: #fcc; color: #000; font-size: 16px;">${(block as any).__error.join('\n').replace(/&/g, '&amp;').replace(/</g, '&lt;')}</pre>`
					} as any);
				}
				bpb = bpb.concat(c);
			}
		} else {
			bpb.push(block);
		}
	})
	return bpb;
}

export function concatChildren(...items: (OpaqueContent | BlockPropsBase[])[]) : OpaqueContent {
	return (items as unknown as BlockPropsBase[][]).reduce((a, b) => a.concat(b)) as unknown as OpaqueContent;
}

export function appendChildren(content: OpaqueContent, ...children: BlockPropsBase[]): OpaqueContent {
	return (content as unknown as BlockPropsBase[]).concat(children) as unknown as OpaqueContent;
}
export function emptyChildren(): OpaqueContent {
	return [] as unknown as OpaqueContent;
}

export default function getChildren(children: OpaqueContent | BlockPropsBase[], wrap: WrapFn = null, call: boolean = false) {
	return flattenChildren(children).map((child, index) => {
		if (child) {
			if (wrap) {
				return wrap(React.createElement(Registry.getBlock(child), child), child, index);
			}
			if (call) {
				let e = Registry.getBlock(child, true);
				while (e.render) e = e.render;
				return e(child);
			}
			return React.createElement(Registry.getBlock(child), {
				key: child.id,
				...child,
			});
		}
		return null;
	}).filter(v => !!v);
}
